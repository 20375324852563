import React, { useContext, useState, useEffect } from "react";
import { TopicContext } from "./TopicContext";
import expertise from "./assets/expertise";
import inspiration from "./assets/inspiration";
import partnerships from "./assets/partnerships";
import "./intro-slide.css";
import http from "../lib/http";

const getRandom = (array) => Math.floor(Math.random() * array.length) + 1;

const randomScientist = getRandom(expertise);
const randomPet = getRandom(inspiration);
const randomPartner = getRandom(partnerships);

const foundScientist =
  randomScientist &&
  expertise.find((obj) => {
    return obj.index === randomScientist;
  });
const foundPet =
  randomPet &&
  inspiration.find((obj) => {
    return obj.index === randomPet;
  });
const foundPartner =
  randomPartner &&
  partnerships.find((obj) => {
    return obj.index === randomPartner + 1;
  });

export const IntroSlide = () => {
  const interactiveId = 2;
  // eslint-disable-next-line
  const [introText, setIntroText] = useState();
  // eslint-disable-next-line
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/items/${interactiveId}`);
      setIntroText(data.item.sceneSelectBody);
    }
    fetchData();
  });
  const {
    // eslint-disable-next-line
    topicType,
    setTopicType, // eslint-disable-next-line
    topicChosen,
    setTopicChosen, // eslint-disable-next-line
    itemChosen,
    setItemChosen, // eslint-disable-next-line
    expertise, // eslint-disable-next-line
    setExpertise, // eslint-disable-next-line
    inspiration, // eslint-disable-next-line
    setInspiration, // eslint-disable-next-line
    partnerships, // eslint-disable-next-line
    setPartnerships,
  } = useContext(TopicContext);

  const handleClick = (e) => {
    setTopicType((topic) => e.target.parentNode.id);
    console.log(e.target.parentNode.id)
    setTopicChosen((topic) => true);
    setItemChosen((topic) => false);
    setTimeout(() => {
      setItemChosen((topic) => true);
    }, 3000);
  };

  return (
    <div className="intro-screen">
      <div className="intro-text">
        <h1>{introText}</h1>
      </div>
      <div className="topic-section-container">
        <div className="topic-option-container" id="expertise">
          <img
            className="topic-image"
            alt="Scientist Portrait"
            onClick={handleClick}
            src={foundScientist.image}
          />
          <button onClick={handleClick} className="topic-button" id="expertise">
            <h3>Our Expertise</h3>
          </button>
        </div>
        <div className="topic-option-container" id="inspiration">
          <img
            onClick={handleClick}
            className="topic-image"
            alt="Pet Portrait"
            src={foundPet.image}
          />
          <button onClick={handleClick} className="topic-button" id="inspiration">
            <h3>Our Inspiration</h3>
          </button>
        </div>
        <div className="topic-option-container" id="partnerships">
          <img
            onClick={handleClick}
            className="topic-image"
            alt="Partner Portrait"
            src={foundPartner.image}
          />
          <button onClick={handleClick} id="partnerships" className="topic-button">
            <h3 >Our Partners</h3>
          </button>
        </div>
      </div>
    </div>
  );
};
